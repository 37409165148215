<template>
  <div class="flex justify-between w-full relative">
    <div
      v-for="(cell, index) in cells"
      :key="cell.key"
      class="w-1/5 px-1"
    >
      <input
        :ref="`${baseRefName}${index}`"
        v-model="cell.value"
        class="w-full mt-0 relative bg-slate-600 border-slate-600 rounded-lg py-3 px-4 border-2 outline-none focus:border-slate-200 focus:ring-0 autofill:bg-slate-600 transition-all text-center disabled:opacity-40"
        autocomplete="off"
        v-bind="$attrs"
        type="tel"
        size="1"
        placeholder="0"
        :disabled="disabled"
        @focus="focusedCellIdx = index"
        @input="onKeyAction(index, $event)"
        @keyup.delete="focusPreviousCell()"
        @paste="onPaste($event)"
      >
    </div>
    <div
      :class="{'opacity-100 visible' : disabled, 'opacity-0 invisible' : !disabled}"
      class="transition-all absolute flex justify-center align-middle left-0 top-0 w-full h-full"
      role="status"
    >
      <Spinner/>
    </div>
  </div>
</template>

<script>
const BASE_REF_NAME = 'code-input';
const CELL_REGEXP = '^\\d{1}$';

export default defineComponent({
  name: 'InputCode',
  props: {
    length: {
      type: Number,
      default: 6,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update',
  ],
  data() {
    return {
      baseRefName: BASE_REF_NAME,
      focusedCellIdx: 0,
      cells: [],
    };
  },
  computed: {
    codeComputed() {
      return this.cells.reduce((pin, cell) => pin + cell.value, '');
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.cells = Array.from({ length: this.length, }, (v, i) => {
        return {
          value: '',
          key: i,
        };
      });
      this.$nextTick(this.focusCellByIndex);
    },
    onKeyAction(index, e) {
      if (e.inputType === 'deleteContentBackward') {
        return;
      }
      if (!this.isTheCellValid(e.data)) {
        this.cells[index].value = '';
        return;
      }
      this.cells[index].value = e.data;
      this.$emit('update', this.codeComputed);
      this.focusNextCell();
    },
    isTheCellValid(cell) {
      return cell ? cell.match(CELL_REGEXP) : false;
    },
    focusPreviousCell() {
      if (!this.focusedCellIdx) { return; }
      this.focusCellByIndex(this.focusedCellIdx - 1);
    },
    focusNextCell() {
      if (this.focusedCellIdx === this.length - 1) { return; }
      this.focusCellByIndex(this.focusedCellIdx + 1);
    },
    focusCellByIndex(index = 0) {
      const ref = `${this.baseRefName}${index}`;
      const el = (this.$refs[ref])[0];
      el.focus();
      el.select();
      this.focusedCellIdx = index;
    },
    onPaste(e) {
      e.preventDefault();
      const pastedText = e.clipboardData.getData('text').trim();
      if (pastedText.length !== this.length || !/^[0-9]+$/.test(pastedText)) { return; }
      pastedText.split('').forEach((digit, i) => {
        this.cells[i].value = digit;
      });
      this.focusCellByIndex(this.length - 1);
      this.$emit('update', this.codeComputed);
    },
  },
});
</script>
